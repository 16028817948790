<template>
  <div id="ranking-wrapper" class="w-full">
    <div class="form-group">
      <label for="filter">Filtrar</label>
      <input id="filter" class="form-control" type="text" @input="filterDataSource" v-model="filterInput">
    </div>
    <table class="">
      <thead>
      <tr>
        <th>#</th>
        <th>Seccion</th>
        <th>Colegio</th>
        <th>Promedio</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(s, i) in sections" :key="'t-'.i">
        <td>{{ i + 1 }}</td>
        <td v-if="s.school">{{ s.school }}</td>
        <td v-if="s">{{ s.name }}</td>
        <td>
        {{  s.acc }}
        {{  s.total }}
      </td>
        <td v-if="s.acc && s.total"> {{ s.acc / s.total }}</td>
        <td v-else>0</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "section-test-ranking",
  props: {
    sections: {
      type: Array,
      required: true
    },
  },
  data: function(){
    return {
      mutData: this.sections,
      filtData: this.sections,
      filterInput: "",
    }
  },
  methods: {
    filterDataSource: function({type, target}){
      this.filtData = this.mutData.filter(item => {
        return (item.grade.name + ' ' + item.name).toLowerCase().match(this.filterInput.toLowerCase())
            || item.grade.school.name.toLowerCase().match(this.filterInput.toLowerCase()) ;
      })
      this.sortElements();
    },
    sortElements: function (){
      this.filtData.sort((x, y) => {
        return (('acc' in y)?(y.acc / y.total): 0) - (('acc' in x )?(x.acc / x.total): 0);
      })
    }
  },
  mounted() {
    this.sortElements();
  }
}
</script>

<style scoped>

</style>