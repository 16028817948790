import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center mx-3 mb-2" }
const _hoisted_2 = { class: "w-full sm:w-2/3 flex items-center" }
const _hoisted_3 = { class: "mr-1 px-2" }
const _hoisted_4 = ["name"]
const _hoisted_5 = { class: "w-full sm:w-1/6 my-1" }
const _hoisted_6 = { class: "flex items-center px-2" }
const _hoisted_7 = ["id", "name"]
const _hoisted_8 = ["for"]
const _hoisted_9 = { class: "w-full sm:w-1/6 my-1" }
const _hoisted_10 = ["name", "value"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, _toDisplayString(String.fromCharCode(97+$props.order) + ')'), 1 /* TEXT */),
      _withDirectives(_createElementVNode("input", {
        name: 'questions['+ $props.qid +'][question_options]['+ $props.order +'][answer]]',
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($props.option.answer) = $event)),
        type: "text",
        class: "form-input",
        required: ""
      }, null, 8 /* PROPS */, _hoisted_4), [
        [_vModelText, $props.option.answer]
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _withDirectives(_createElementVNode("input", {
          id: "is_correct_answer_" + $props.qid + "_" + $props.order,
          name: 'questions['+ $props.qid +'][question_options]['+ $props.order +'][is_correct_answer]',
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($props.option.is_correct_answer) = $event)),
          type: "checkbox",
          class: "h-5 w-5 text-blue-600"
        }, null, 8 /* PROPS */, _hoisted_7), [
          [_vModelCheckbox, $props.option.is_correct_answer]
        ]),
        _createElementVNode("label", {
          class: "ml-2 text-gray-700",
          for: "is_correct_answer_" + $props.qid + "_" + $props.order
        }, "Es la respuesta", 8 /* PROPS */, _hoisted_8)
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("button", {
        onClick: _cache[2] || (_cache[2] = _withModifiers($event => ($props.delCallback($props.order)), ["prevent"])),
        class: "btn btn-outline-danger"
      }, "Eliminar")
    ]),
    _createElementVNode("input", {
      name: 'questions['+ $props.qid +'][question_options]['+ $props.order +'][order]]',
      value: $props.order,
      type: "hidden"
    }, null, 8 /* PROPS */, _hoisted_10)
  ]))
}