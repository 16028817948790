import { App } from "vue";
import Modal from './components/modal.vue';

export function setupComponents(vue: App<Element>) {
    vue.component('role', require('./components/role.vue').default);
    vue.component('modal',Modal);
    vue.component('user', require('./components/user.vue').default);
    vue.component('avatar', require('./components/avatar.vue').default);
    vue.component('avatar-editor', require('./components/AvatarEditor.vue').default);
    vue.component('audio-recording', require('./components/AudioRecording.vue').default);
    vue.component('rating-stars', require('./components/RatingStars.vue').default);
    vue.component('test-timer', require('./components/TestTimer.vue').default);
    vue.component('test-questions', require('./components/TestQuestions.vue').default);
    vue.component('test-question', require('./components/TestQuestion.vue').default);

    vue.component('delete-button', require('./components/DeleteButton.vue').default);
    vue.component('reading-questions-form', require('./components/ReadingQuestionsForm.vue').default);
    vue.component('question-form', require('./components/QuestionForm.vue').default);
    vue.component('question-options-form', require('./components/QuestionsOptionsForm.vue').default);
    vue.component('tab', require('./components/TabComponent.vue').default);
    vue.component('tab-layout', require('./components/TabLayoutComponent.vue').default);
    vue.component('dual-list-box', require('./components/DualListBox.vue').default);
    vue.component('basic-report', require('./components/BasicReport.vue').default);

    vue.component('pie-chart', require('./components/PieChart.vue').default);
    vue.component('bar-chart', require('./components/BarChart.vue').default);
    vue.component('level-chart', require('./components/LevelChart.vue').default);
    vue.component('student-progress', require('./views/StudentProgress.vue').default);
    vue.component('test-list', require('./views/TestList.vue').default);
    vue.component('section-test-ranking', require('./views/SectionTestRanking.vue').default);
}