import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-4 border border-gray-300 rounded-lg mb-2 items-center ml-4" }
const _hoisted_2 = { class: "mb-4" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { class: "flex flex-wrap mt-2" }
const _hoisted_5 = { class: "w-full sm:w-2/3" }
const _hoisted_6 = ["name"]
const _hoisted_7 = { class: "w-full sm:w-1/3 mt-2 sm:mt-0 sm:pl-2" }
const _hoisted_8 = ["name"]
const _hoisted_9 = ["name", "value"]
const _hoisted_10 = { class: "mb-4" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_question_options_form = _resolveComponent("question-options-form")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, "Pregunta " + _toDisplayString($props.order + 1), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _withDirectives(_createElementVNode("input", {
            name: 'questions[' + $props.order +'][statement]',
            type: "text",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($props.question.statement) = $event)),
            class: "form-input",
            required: ""
          }, null, 8 /* PROPS */, _hoisted_6), [
            [_vModelText, $props.question.statement]
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("button", {
            onClick: _cache[1] || (_cache[1] = _withModifiers($event => ($props.delCallback($props.order)), ["prevent"])),
            class: "btn btn-outline-danger"
          }, "Eliminar")
        ])
      ]),
      _withDirectives(_createElementVNode("input", {
        name: 'questions[' + $props.order +'][id]',
        type: "hidden",
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.id) = $event))
      }, null, 8 /* PROPS */, _hoisted_8), [
        [_vModelText, _ctx.id]
      ]),
      _createElementVNode("input", {
        name: 'questions[' + $props.order +'][order]',
        type: "hidden",
        value: $props.order
      }, null, 8 /* PROPS */, _hoisted_9)
    ]),
    _createElementVNode("div", _hoisted_10, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.question.question_options, (o, index) => {
        return (_openBlock(), _createBlock(_component_question_options_form, {
          key: index.toString() + $props.order.toString(),
          order: index,
          qid: $props.order,
          option: o,
          delCallback: $options.del
        }, null, 8 /* PROPS */, ["order", "qid", "option", "delCallback"]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _createElementVNode("button", {
      onClick: _cache[3] || (_cache[3] = _withModifiers((...args) => ($options.add && $options.add(...args)), ["prevent"])),
      class: "underline"
    }, "Agregar Opción")
  ]))
}