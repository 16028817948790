<template>
  <div>
    <div class="flex my-2 space-x-2">
      <div v-for="i in starMax" class="w-10 h-10">
        <svg @mouseover="tempSelected = i" @mouseout="tempSelected = selected" @click='interactive && (selected = i, tempSelected = i)' :fill='(tempSelected < i) ? "#d9d9d9" : "#851cff"' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <title>ionicons-v5-e</title><path d="M394,480a16,16,0,0,1-9.39-3L256,383.76,127.39,477a16,16,0,0,1-24.55-18.08L153,310.35,23,221.2A16,16,0,0,1,32,192H192.38l48.4-148.95a16,16,0,0,1,30.44,0l48.4,149H480a16,16,0,0,1,9.05,29.2L359,310.35l50.13,148.53A16,16,0,0,1,394,480Z"/>
        </svg>
      </div>
      <input type="hidden" name="rating" v-model="selected">
    </div>
  </div>
</template>

<script>
export default {
name: "RatingStars",
  props: ['rating', 'min', 'max', 'interactive'],
  data(){
  return{
    starMin: 0,
    starMax: 0,
    selected: 10,
    tempSelected: 0,
  }
  },
  mounted(){
    this.starMin = this.min;
    this.starMax = this.max;
    console.log(this.rating);
    this.selected = this.rating;
  }
}
</script>

<style scoped>

</style>