import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "flex h-96 w-full" }
const _hoisted_3 = { class: "border border-gray-600 rounded overflow-auto w-full py-2" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "w-32 grid grid-cols-1" }
const _hoisted_6 = { class: "border border-gray-600 rounded overflow-auto w-full py-2" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["value", "name"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.filtSource, (s, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "px-2 cursor-pointer hover:underline",
            key: 's'+index.toString()+s.name,
            onClick: _withModifiers($event => ($options.addToDestination(index)), ["prevent"])
          }, _toDisplayString(s.name), 9 /* TEXT, PROPS */, _hoisted_4))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = _withModifiers($event => ($options.addAllToDestination()), ["prevent"])),
          class: "btn btn-secondary"
        }, ">>"),
        _createElementVNode("button", {
          onClick: _cache[1] || (_cache[1] = _withModifiers($event => ($options.addAllToSource()), ["prevent"])),
          class: "btn btn-secondary"
        }, "<<")
      ]),
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.mutDest, (d, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "px-2 curso-pointer hover:underline",
            key: 'd'+index.toString()+d.name,
            onClick: _withModifiers($event => ($options.addToSource(index)), ["prevent"])
          }, [
            _createTextVNode(_toDisplayString(d.name) + " ", 1 /* TEXT */),
            _createElementVNode("input", {
              type: "hidden",
              value: d.id,
              name: _ctx.formName+'['+index.toString()+']'
            }, null, 8 /* PROPS */, _hoisted_8)
          ], 8 /* PROPS */, _hoisted_7))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ])
  ]))
}