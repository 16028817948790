import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4" }
const _hoisted_2 = { class: "px-4 py-4 border bg-white text-center md:text-left" }
const _hoisted_3 = { class: "font-bold text-lg text-gray-700" }
const _hoisted_4 = { class: "border-b" }
const _hoisted_5 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys($options.groupedReadings), (name) => {
      return (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("h2", _hoisted_3, _toDisplayString(name), 1 /* TEXT */),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.groupedReadings[name], (reading) => {
          return (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("a", {
              class: "hover:text-blue-600 underline text-left text-sm py-1",
              href: '/reading/'+ reading.id +'/progress'
            }, _toDisplayString(reading.title), 9 /* TEXT, PROPS */, _hoisted_5)
          ]))
        }), 256 /* UNKEYED_FRAGMENT */))
      ]))
    }), 256 /* UNKEYED_FRAGMENT */))
  ]))
}