import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center justify-center" }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = {
  key: 0,
  class: "mt-4"
}
const _hoisted_4 = ["textContent"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: _normalizeClass(["btn", {'btn-primary': !$data.isRecording, 'btn-outline-primary': $data.isRecording}]),
      type: "button",
      onClick: _cache[0] || (_cache[0] = $event => ($options.recordAudio())),
      textContent: _toDisplayString($data.recordButtonText)
    }, null, 10 /* CLASS, PROPS */, _hoisted_2),
    ($data.timer > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createTextVNode(" Grabando... "),
          _createElementVNode("span", {
            class: "stat",
            textContent: _toDisplayString($data.timer)
          }, null, 8 /* PROPS */, _hoisted_4),
          _createTextVNode(" segundos ")
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}