

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active[data-v-f838f7ed] {
  transition: all .3s ease;
}
.slide-fade-leave-active[data-v-f838f7ed] {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter[data-v-f838f7ed], .slide-fade-leave-to[data-v-f838f7ed]
  /* .slide-fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
