<template>
  <transition name="bounce">
    <div class="d-flex" id="floating-timer" v-if="showTimer">
      <div v-text="timeRemainingFormatted"></div>
    </div>
  </transition>
</template>

<script>
export default {
name: "TestTimer",
  props: ['duration'],
  data(){
  return{
    timeRemaining: 0,
    timeInterval: 0,
    showTimer: false,
  }
  },
  computed:{
    timeRemainingFormatted: function(){
      return new Date( this.timeRemaining * 1000).toISOString().substr(11, 8)
    }
  },
  mounted(){
    this.timeRemaining = this.duration;
    if(this.timeRemaining > 0){
      this.showTimer = true;
      this.startTimer(this.duration)
    }else{
      this.hideTimer();
    }
  },
  methods: {
    hideTimer(){
      this.showTimer = false;
    },
    startTimer() {
      var that = this;
      this.timeInterval = window.setInterval(function(){
        that.timeRemaining--;
        if(that.timeRemaining === 0){
          clearInterval(that.timeInterval);
          that.$emit('times-up');
        }
      }, 1000)
    }
  }
}
</script>

<style scoped lang="scss">
#floating-timer{
  position:fixed;
  top: 100px;
  right: 10px;
  width: 250px;
  height: 120px;
  background: #9182fd;
  border-radius: 20px;
  box-shadow: 5px 5px 5px rgba(0,0,0,0.2);
  color: white;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

</style>