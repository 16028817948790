#progressBar[data-v-db40b927] {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: #9182fd;
  padding: 15px;
  text-align: center;
  z-index: 10;
}
.overview-question[data-v-db40b927] {
  background: white;
  text-color: #8148ff;
  font-size: 3rem;
  height: 75px;
  width: 75px;
  margin: 10px;
  border-radius: 10px;
}
.overview-question.answered[data-v-db40b927] {
  background: #8148ff;
  color: white !important;
}