import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: ""
}
const _hoisted_2 = {
  key: 0,
  class: "font-bold pb-4 relative",
  style: {"z-index":"999!important"}
}
const _hoisted_3 = ["textContent"]
const _hoisted_4 = ["src", "alt"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", null, [
    (!this.disable_say)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_Transition, { name: "bounce" }, {
            default: _withCtx(() => [
              (_ctx.message.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createElementVNode("div", {
                      style: {"background":"#5e6bf2","padding":"10px","border-radius":"10px","color":"white","float":"left"},
                      textContent: _toDisplayString(this.message)
                    }, null, 8 /* PROPS */, _hoisted_3)
                  ]))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          })
        ]))
      : _createCommentVNode("v-if", true),
    ($props.options.length)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "h-32 md:h-56 aspect-[1/1.5] relative mx-auto",
          onClick: _cache[0] || (_cache[0] = $event => ($options.sayRandomPhrase()))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.options, (option) => {
            return (_openBlock(), _createElementBlock("img", {
              src: '/storage/' + option.image,
              alt: 'Imagen de un ' + option.name,
              class: _normalizeClass('absolute inset-0 mx-auto object-contain'),
              style: _normalizeStyle('z-index: ' + option.avatar_option_type.order)
            }, null, 12 /* STYLE, PROPS */, _hoisted_4))
          }), 256 /* UNKEYED_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}