#floating-timer[data-v-53ecc14b] {
  position: fixed;
  top: 100px;
  right: 10px;
  width: 250px;
  height: 120px;
  background: #9182fd;
  border-radius: 20px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
  color: white;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}