<template>
  <button class="btn btn-secondary" v-on:click="callDelete">Eliminar</button>
</template>

<script>
export default {
  name: "delete-button",
  props: ['url'],
  methods: {
    callDelete: function(event){
      axios.delete(this.url).then(function (response) {
        window.location.reload();
      }).catch(function (error){
        console.log(error.response.data);
      })
    }
  }
}
</script>

<style scoped>

</style>