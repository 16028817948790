<template>
  <div>
    <test-timer :duration="duration" v-on:times-up="submitForm()"></test-timer>
    <div v-for="(question, index) in questions">
      <test-question v-bind:question="question" v-bind:options="question.question_options"  v-on:answered-question="questionAnswered($event, index)"></test-question>
    </div>
    <div id="progressBar">
      <div class="h2 text-left font-weight-bold container">
        Preguntas respondidas
        <span class="h4">
          ({{questions.filter(x => x.answer).length}}/{{questions.length}}):
        </span>
      </div>
      <div class="container mx-auto d-flex justify-content-between align-items-center">
        <div class="d-flex">
          <a :href="'#question_' + question.id" v-for="question in questions" class="overview-question" :class="{'answered': question.answer != -1}">
            {{parseInt(question.order) + 1}}<span class="h4" v-if="question.answer != -1">
            ({{String.fromCharCode(97 + parseInt(question.answer))}})
            </span>
          </a>
        </div>
          <button class="btn btn-primary btn-lg" id="submitButton">Envíar examen</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TestQuestions",
  props: ['duration', 'prop_questions'],
  data(){
    return{
      questions: this.prop_questions
    }
  },
  mounted(){
    // set all answers in questions to -1
    this.questions.forEach((question) => {
      question.answer = -1;
    }); 
  },
  methods: {
    questionAnswered: function(event, index){
      let oldElement = this.questions[index];
      oldElement.answer = event;
      this.questions[index] = oldElement;
    },
    onlyUnique: function(value, index, self) {
      return self.indexOf(value) === index;
    },
    submitForm(){
      alert("Se te acabó el tiempo...Envíaremos la lectura como esté");
      document.querySelector('#submitButton').click()
    }
  }
}
</script>
<style lang="scss" scoped>
  #progressBar{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: #9182fd;
    padding: 15px;
    text-align: center;
    z-index: 10;
  }
  .overview-question{
    background: white;
    text-color: #8148ff;
    font-size: 3rem;
    height: 75px;
    width: 75px;
    margin: 10px;
    border-radius: 10px;

    &.answered{
      background: #8148ff;
      color: white!important;
    }
  }
</style>