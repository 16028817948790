import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ml-4 mb-4" }
const _hoisted_2 = { class: "mb-4 mt-4" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_question_form = _resolveComponent("question-form")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "underline",
      onClick: _cache[0] || (_cache[0] = (...args) => ($options.add && $options.add(...args)))
    }, "Agregar Pregunta"),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questions, (q, index) => {
        return (_openBlock(), _createBlock(_component_question_form, {
          key: 'q' + index,
          order: index,
          question: q,
          delCallback: $options.del
        }, null, 8 /* PROPS */, ["order", "question", "delCallback"]))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ]))
}