<template>
  <div class="mx-auto max-w-screen-xl px-7">
    <div class="flex flex-col md:flex-row">

      <div class="mb-4 mx-auto">
        <div class="relative w-64 h-80 md:w-80 md:h-96">
          <div v-for="avatar_option_type in avatar_option_types" :key="avatar_option_type.id">
            <span v-for="option in avatar_option_type.avatar_options" :key="option.id">
              <img :src="'/storage/' + option.image" :alt="'Imagen de un ' + option.name"
                class="absolute left-0 top-0 w-64 h-80 md:w-80 md:h-96 object-contain mx-auto"
                :class="avatar_option_type.order" v-if="selected_options.includes(option.id)">
            </span>
          </div>
        </div>

        <transition name="bounce">
          <div class="font-bold relative" v-if='message.length > 0' :style="{ zIndex: 999 }">
            <div class="bg-indigo-600 p-2 rounded-lg text-white float-left" v-text="this.message"
              style="z-index: 999!important;"></div>
          </div>
        </transition>
      </div>

      <div class="w-full md:w-auto">
        <!-- Opciones -->
        <div class="grid grid-cols-3 md:grid-cols-5 items-center justify-center justify-items-center">
          <div v-for="avatar_option_type in avatar_option_types" :key="avatar_option_type.id"
            class="flex justify-center">
            <button :data-nightwatch="'avatar-option-type-' + avatar_option_type.id" type="button" class="btn" :class="{
              'btn-secondary': active_type === avatar_option_type.id,
              'btn-tertiary': active_type !== avatar_option_type.id
            }" @click="active_type = avatar_option_type.id" v-text="avatar_option_type.name"></button>
          </div>
        </div>

        <!-- Objetos -->
        <div v-for="avatar_option_type in avatar_option_types" class="mt-4" :key="avatar_option_type.id">
          <div v-if="active_type === avatar_option_type.id">
            <h2 class="font-bold text-3xl" v-text="avatar_option_type.name"></h2>
            <div class="flex flex-wrap">
              <div class="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-2 my-2">
                <div v-for="avatar_option in avatar_option_type.avatar_options" :key="avatar_option.id"
                  class="p-2 rounded-sm" :class="{
                    'bg-primary': is_option_selected(avatar_option_type.id, avatar_option.id),
                    'border border-primary': !is_option_selected(avatar_option_type.id, avatar_option.id),
                    'cursor-pointer': user_xp >= avatar_option.xp_needed,
                    'cursor-not-allowed relative': user_xp < avatar_option.xp_needed
                  }"
                  @click="user_xp >= avatar_option.xp_needed && select_option(avatar_option_type.id, avatar_option.id)"
                  :data-nightwatch="'avatar-option-' + avatar_option.id">
                  <div
                    class="absolute bg-black opacity-70 inset-0 flex items-center justify-center text-white"
                    v-if="user_xp < avatar_option.xp_needed">
                    <div>
                      <div>Necesitas</div>
                      <div v-text="avatar_option.xp_needed + ' xp'"></div>
                    </div>
                  </div>
                  <img :src="'/storage/' + avatar_option.image" :alt="'Imagen de ' + avatar_option.name"
                    class="w-full">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-end">
          <form class="mt-4" :action="'/user_avatars/' + user_avatar.id" method="POST">
            <input type="hidden" name="_method" value="PATCH">
            <input type="hidden" name="_token" :value="csrf">
            <input type="hidden" name="avatar_options[]" :value="option" v-for="option in selected_options"
              :key="option">

            <button class="btn btn-primary mt-4" @click="save_avatar()" data-nightwatch="save-avatar">
              Guardar avatar
            </button>
          </form>
          <a href="/" class="btn ml-2">Cancelar</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "avatar-editor",
  props: ['avatar_option_types', 'user_avatar', 'csrf', 'user_xp'],
  data: function () {
    return {
      selected_options_by_type: {},
      selected_options: [],
      active_type: '',
      message: ''
    }
  },
  mounted() {
    this.selected_options = this.user_avatar.avatar_options.map(el => el.id)
    var that = this;
    this.user_avatar.avatar_options.forEach(option => {
      that.selected_options_by_type[option.avatar_option_type_id] = option.id
    })
    this.active_type = this.avatar_option_types[0].id;
  },
  methods: {
    is_option_selected(type, id) {
      return this.selected_options.includes(id);
    },
    select_option(type, id) {
      // remove old from array of selected options
      let remove_id = this.selected_options_by_type[type];
      // filter out the
      this.selected_options = this.selected_options.filter(item => item !== remove_id)
      // add new to array of selected options
      this.selected_options_by_type[type] = id
      this.selected_options.push(id);
    },
    say: function (message, duration = 2000) {
      this.message = message;
      var that = this;
      setTimeout(function () {
        that.message = '';
      }, duration)
    },
    save_avatar() {
    }
  }
}
</script>

<style scoped></style>
