<template>
  <div class="p-4 border border-gray-300 rounded-lg mb-2 items-center ml-4">
    <div class="mb-4">
      <label class="font-bold">Pregunta {{ order + 1 }}</label>
      <div class="flex flex-wrap mt-2">
        <div class="w-full sm:w-2/3">
          <input v-bind:name="'questions[' + order +'][statement]'" type="text" v-model="question.statement" class="form-input" required>
        </div>
        <div class="w-full sm:w-1/3 mt-2 sm:mt-0 sm:pl-2">
          <button @click.prevent="delCallback(order)" class="btn btn-outline-danger">Eliminar</button>
        </div>
      </div>
      <input v-bind:name="'questions[' + order +'][id]'" type="hidden" v-model="id">
      <input v-bind:name="'questions[' + order +'][order]'" type="hidden" :value="order">
    </div>

    <div class="mb-4">
      <question-options-form
          v-for="(o, index) in this.question.question_options"
          v-bind:key="index.toString() + order.toString()"
          v-bind:order="index"
          v-bind:qid="order"
          v-bind:option="o"
          v-bind:delCallback="del"
      ></question-options-form>
    </div>
       <button @click.prevent="add" class="underline">Agregar Opción</button> 
  </div>
</template>

<script>
export default {
  name: "question-form",
  props: [
      'order',
      'question',
      'delCallback'
  ],
  data: function (){
    return {
      id: this.question.id,
      nextIndex:this.question.question_options.length
    }
  },
  methods: {
    add: function (){
      this.question.question_options.push({
        order: ++this.nextIndex,
        answer: '',
        is_correct_answer: false,
      });
    },
    del: function (index){
      this.question.question_options.splice(index, 1);
    },
  }
}
</script>

<style scoped>

</style>