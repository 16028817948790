<template>
  <div class="flex justify-center lg:justify-start">
    <div class="w-full max-w-md p-4">
      <div class="mb-4">
        <input v-model="form.name" type="text" name="name" placeholder="Nombre de rol" class="w-full px-3 py-2 border border-gray-300 rounded text-center lg:text-left" :class="{'is-invaild': form.errors.has('name')}">
        <has-error :form="form" field="name" class="text-red-500"></has-error>
      </div>
       <fieldset label="Asignar Permisos">
        <label v-for="option in permissions">
        <input type="checkbox"
            v-model="form.permissions"
            :key="option.name"
            :value="option.name"
            name="flavour-3a">
            {{ option.name }}
        </label>
      </fieldset>
    </div>
  </div>
  <div class="flex justify-between items-center py-4 px-6">
    <button type="submit" variant="primary" class="btn-lg btn-primary" v-if="!dis" disabled>Creando Rol</button>
    <button type="submit" v-if="dis" @click.prevent="createRole()" class="btn btn-lg btn-primary"><i class=""></i> Guardar Rol</button>
  </div>
</template>

<script>
import Form from 'vform'
import axios from 'axios'
export default {
  data(){
    return{
      dis: true,
      permissions: [],
      form: new Form({
        'name' : '',
        'permissions' : []
      })
    }
  },
  methods:{
    getPermissions(){
      axios.get("/getAllPermission")
          .then((response)=>{
            this.permissions = response.data.permissions
          }).catch(()=>{
          alert("Error obteniendo permisos")
      });
    },
    createRole(){
      this.dis = false;
      this.form.post("/postRole").then(()=>{
        alert("Rol creado correctamente");
        window.location = "/role";
      }).catch((e)=>{
        alert("Error creando rol");
        console.log(e)
      });
      this.dis=true;
    }
  },
  created(){
    this.getPermissions();
  }
}
</script>

