<template>
  <div class="grid grid-cols-1  lg:grid-cols-2 gap-4">
    <div v-for="(m, i) in modules" :key="'m-'.i" class="border px-2 py-1">
      <div class="flex items-center justify-between py-2">
        <h4 class="font-bold text-xl">
          {{ m.name }} 
        </h4>
          <div class="px-2 py-1 text-sm rounded" v-if="moduleLevels" :class="levelStyle[JSON.parse(moduleLevels)[i]['level']]">
          {{ JSON.parse(moduleLevels)[i]["level"] }}
          </div>
        </div>
      <div class="">
        <div class="h-4" role="progressbar" v-if="m.name in userData"
             :style="{width: (100*(userData[m.name].length)/(m.readings.length))+'%' }"
             aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
        <div class="h-4" role="progressbar" v-else style="width: 0" aria-valuenow="0"
             aria-valuemin="0" aria-valuemax="100"></div>
      </div>
        <div>
          <table class="w-full">
            <tbody v-if="m.name in userData">
            <tr v-for="(d, i) in userData[m.name]" class="border-b">
              <td><a :href="'/report/test/'+ d.test_id" class="underline"> {{ d.reading_name }} </a></td>
              <td>{{ d['points'] + '/' + d['total_points'] }} - </td>
              <td>{{ (parseInt(d['elapsed_time'] / 60)) + ':' + (padZeros(d['elapsed_time'] % 60, 2)) + ' min' }}</td>
              <td>
                <a class="btn btn-tertiary"
                     :href="'/tests/'+ d['test_id'] + '/details?user_id=' + d['user_id']">Detalle</a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "student-progress",
  props: {
    modules: {
      type: Object,
      required: true
    },
    moduleLevels: {
      type: Object,
      required: true
    },
    userData: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      showIndex: "1",
      levelStyle: {
        "Previo al Inicio": "bg-gray-100 text-gray-700",
        "Inicio": "bg-red-100 text-red-700",
        "Proceso": "bg-orange-100 text-orange-600",
        "Satisfactorio": "bg-orange-100 text-green-600",
      }
    }
  },
  methods: {
    padZeros: function (number, size) {
      var s = String(number);
      while (s.length < (size || 2)) {
        s = "0" + s;
      }
      return s;
    }
  }
}
</script>

<style scoped>

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>