import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f838f7ed"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid grid-cols-1 lg:grid-cols-2 gap-4" }
const _hoisted_2 = { class: "flex items-center justify-between py-2" }
const _hoisted_3 = { class: "font-bold text-xl" }
const _hoisted_4 = { class: "" }
const _hoisted_5 = {
  key: 1,
  class: "h-4",
  role: "progressbar",
  style: {"width":"0"},
  "aria-valuenow": "0",
  "aria-valuemin": "0",
  "aria-valuemax": "100"
}
const _hoisted_6 = { class: "w-full" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "border-b" }
const _hoisted_9 = ["href"]
const _hoisted_10 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.modules, (m, i) => {
      return (_openBlock(), _createElementBlock("div", {
        key: 'm-'.i,
        class: "border px-2 py-1"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h4", _hoisted_3, _toDisplayString(m.name), 1 /* TEXT */),
          ($props.moduleLevels)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["px-2 py-1 text-sm rounded", _ctx.levelStyle[JSON.parse($props.moduleLevels)[i]['level']]])
              }, _toDisplayString(JSON.parse($props.moduleLevels)[i]["level"]), 3 /* TEXT, CLASS */))
            : _createCommentVNode("v-if", true)
        ]),
        _createElementVNode("div", _hoisted_4, [
          (m.name in $props.userData)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "h-4",
                role: "progressbar",
                style: _normalizeStyle({width: (100*($props.userData[m.name].length)/(m.readings.length))+'%' }),
                "aria-valuenow": "25",
                "aria-valuemin": "0",
                "aria-valuemax": "100"
              }, null, 4 /* STYLE */))
            : (_openBlock(), _createElementBlock("div", _hoisted_5))
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("table", _hoisted_6, [
            (m.name in $props.userData)
              ? (_openBlock(), _createElementBlock("tbody", _hoisted_7, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.userData[m.name], (d, i) => {
                    return (_openBlock(), _createElementBlock("tr", _hoisted_8, [
                      _createElementVNode("td", null, [
                        _createElementVNode("a", {
                          href: '/report/test/'+ d.test_id,
                          class: "underline"
                        }, _toDisplayString(d.reading_name), 9 /* TEXT, PROPS */, _hoisted_9)
                      ]),
                      _createElementVNode("td", null, _toDisplayString(d['points'] + '/' + d['total_points']) + " - ", 1 /* TEXT */),
                      _createElementVNode("td", null, _toDisplayString((parseInt(d['elapsed_time'] / 60)) + ':' + ($options.padZeros(d['elapsed_time'] % 60, 2)) + ' min'), 1 /* TEXT */),
                      _createElementVNode("td", null, [
                        _createElementVNode("a", {
                          class: "btn btn-tertiary",
                          href: '/tests/'+ d['test_id'] + '/details?user_id=' + d['user_id']
                        }, "Detalle", 8 /* PROPS */, _hoisted_10)
                      ])
                    ]))
                  }), 256 /* UNKEYED_FRAGMENT */))
                ]))
              : _createCommentVNode("v-if", true)
          ])
        ])
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}