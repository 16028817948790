<template>
  <div class="w-full">
    <div class="flex h-96 w-full">
      <div class="border border-gray-600 rounded overflow-auto w-full py-2">
        <div class="px-2 cursor-pointer hover:underline" v-for="(s, index) in this.filtSource"  :key="'s'+index.toString()+s.name" @click.prevent="addToDestination(index)">
          {{s.name}}
        </div>
      </div>
      <div class="w-32 grid grid-cols-1">
          <button @click.prevent="addAllToDestination()" class="btn btn-secondary">>></button>
          <button @click.prevent="addAllToSource()" class="btn btn-secondary"><<</button>
      </div>
      <div class="border border-gray-600 rounded overflow-auto w-full py-2">
        <div class="px-2 curso-pointer hover:underline" v-for="(d, index) in this.mutDest" :key="'d'+index.toString()+d.name" @click.prevent="addToSource(index)">
          {{d.name}}
          <input type="hidden" :value="d.id" :name="formName+'['+index.toString()+']'">
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "question-form",
  props: [
    'source',
    'destination',
    'form-name'
  ],
  data: function (){
    return {
      mutSource: this.source,
      filtSource: this.source,
      mutDest: this.destination,
      filterInput: "",
    }
  },
  methods: {
    addToSource: function (index){
      let aux = this.mutDest[index];
      this.mutDest.splice(index,1);
      this.mutSource.push(aux);

      // maintain state
      this.mutSource.sort(function(a, b){return a.id - b.id});
      let f = this.filterInput
      this.filtSource = this.mutSource.filter(function(item){
        return item.name.match(f);
      })
    },
    addToDestination: function(index){
      let aux = this.filtSource[index];
      for(var i = 0; i < this.mutSource.length; i++){
        if(this.mutSource[i].id == aux.id){
          this.mutSource.splice(i, 1);
        }
      }
      // maintain state
      this.mutSource.sort(function(a, b){return a.id - b.id});
      let f = this.filterInput
      this.filtSource = this.mutSource.filter(function(item){
        return item.name.match(f);
      })
      this.mutDest.push(aux);
    },
    addAllToSource: function(){
      this.mutSource = this.mutSource.concat(this.mutDest);
      this.mutDest = [];
      // maintain state
      this.mutSource.sort(function(a, b){return a.id - b.id});
      let f = this.filterInput
      this.filtSource = this.mutSource.filter(function(item){
        return item.name.match(f);
      })
    },
    addAllToDestination: function (){
      this.mutDest = this.mutDest.concat(this.filtSource);

      let aux = this.filtSource
      this.mutSource = this.mutSource.filter(x => !aux.includes(x));

      // maintain state
      this.mutSource.sort(function(a, b){return a.id - b.id});
      let f = this.filterInput
      this.filtSource = this.mutSource.filter(function(item){
        return item.name.match(f);
      })
    },
  }
}
</script>

<style scoped>

</style>
