<template>
  <div class="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4">
    <div v-for="name in Object.keys(groupedReadings)" class="px-4 py-4 border bg-white text-center md:text-left">
      <h2 class="font-bold text-lg text-gray-700"> {{ name }} </h2>
      <div v-for="reading in groupedReadings[name]" class="border-b">
        <a class="hover:text-blue-600 underline text-left text-sm py-1" :href="'/reading/'+ reading.id +'/progress'">
          {{ reading.title }}
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "test-list",
  props: {
    readings: {
      type: Array,
      required: true
    }
  },
  computed: {
    groupedReadings() {
      let groups = this.filteredData.reduce((acc, item) => {
        if(!acc[item.module.name]){
          acc[item.module.name] = [];
        }
        acc[item.module.name].push(item);
        return acc;
      }, {});

      // sort groups by module id
      return Object.keys(groups).sort((a, b) => {
        return groups[a][0].module.id - groups[b][0].module.id;
      }).reduce((acc, key) => {
        acc[key] = groups[key];
        return acc;
      }, {});
    },
    filteredData(){
      return this.readings.filter(item => {
        return item.title.toLowerCase().match(this.filterInput.toLowerCase())
            || item.module.name.toLowerCase().match(this.filterInput.toLowerCase()) ;
      })
    }
  },
  data: function(){
    return {
      filterInput: "",
    }
  },
}
</script>

<style scoped>

</style>