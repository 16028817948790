import { createApp } from 'vue/dist/vue.esm-bundler.js';
import { setupComponents } from './component';
import { HasError, AlertError } from 'vform/src/components/bootstrap5'
import JsonExcel from 'vue-json-excel3';

require("./tailwind.css")
let app = createApp({});

app.component("downloadExcel", JsonExcel);
app.component(HasError.name!, HasError)
app.component(AlertError.name!, AlertError)

setupComponents(app);
let a = app.mount('#app');
    
// Swiper js
 import Swiper from 'swiper';
 import 'swiper/swiper-bundle.css';

 function docReady(fn) {
    // see if DOM is already available
    if (document.readyState === "complete" || document.readyState === "interactive") {
        // call on next available tick
        setTimeout(fn, 1);
    } else {
        document.addEventListener("DOMContentLoaded", fn);
    }
}    

docReady(()=>{
    window.setTimeout(()=>{

 const swiper = new Swiper('.swiper-container', {
    // Optional parameters
    // direction: 'vertical',
    loop: true,
    slidesPerView: 1,
    // Responsive breakpoints
    breakpoints: {
    550: {
      slidesPerView: 2,
      spaceBetween: 20
    },
    720: {
      slidesPerView: 3,
      spaceBetween: 30
    },
    880: {
      slidesPerView: 4,
      spaceBetween: 40
    }
    },
    centeredSlides: true,
    autoplay: {
      delay: 1000,
      disableOnInteraction: true,
    },
    // // If we need pagination
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    spaceBetween: 50,
  });
    }, 3000)
})

document.addEventListener("DOMContentLoaded", function (event) {

  window.say = function (message) {
    a.$refs.avatar.say(message)
  }

 let profileButton = document.querySelector('#profileButton');
  profileButton?.addEventListener('click', function () {
    let profileDropdown = document.querySelector('#profileDropdown');
    profileDropdown?.classList.toggle('hidden');
  });


 let adminButton = document.querySelector('#adminButton');
  adminButton?.addEventListener('click', function () {
    let adminDropdown = document.querySelector('#adminDropdown');
    adminDropdown?.classList.toggle('hidden');
  });
});
