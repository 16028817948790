<template>
  <div class="flex justify-between items-center">
    <h1 class="font-bold text-3xl text-center sm:text-left">
      Usuarios
    </h1>
    <button @click="fetchAll()" v-if="!showAll" class="btn btn-tertiary">Cargar todos</button>
    <button class="underline text-[#172554]" @click="showModal=true">
      Agregar Usuario
    </button>
  </div>
  <div class="overflow-x-auto">
    <table class="table-auto w-full ">
      <thead>
        <tr class="text-left">
          <th class="px-4 py-2">Nombre</th>
          <th class="px-4 py-2">Colegio</th>
          <th class="px-4 py-2">Código</th>
          <th class="px-4 py-2">Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user.id">
          <td class="small">
            {{ user.name }}
            <span
              class="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200 "
              v-for="role in user.roles">
              <svg class="h-1.5 w-1.5" viewBox="0 0 6 6" aria-hidden="true" :class="classMap[role.name]">
                <circle cx="3" cy="3" r="3" />

              </svg>
              {{ role.name }}
            </span>

          </td>
          <td class="small text-secondary">
            {{ user.school }} -
            <span v-if="user.section">
              {{ user.section.grade.name }}
              {{ user.section.name }}
            </span>
          </td>
          <td>
            {{ user.email }}
          </td>
          <td>
            <a class="mx-2 px-2" v-bind:href="'/user/' + user.id + '/roles'"><u>Roles</u></a>
            <a href="#" @click="showSectionModal(user.id, user.section_id)">
              <u> Asignar sección </u>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4">
      <modal :showModal="showModal" @close="showModal = false">
        <!-- Create User Modal -->
        <div class="modal fade" id="createUser" tabindex="-1" role="dialog" aria-labelledby="createUserModalLabel"
          aria-hidden="true">
          <div class="modal-dialog max-w-lg mx-auto" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title font-bold" id="createUserModalLabel">Crear Usuario</h5>
              </div>
              <form @submit.prevent="createUser()">
                <div class="modal-body">
                  <div class="mb-4">
                    <label>Nombre</label>
                    <input v-model="form.name" type="text" name="name" placeholder="Nombre"
                      class="w-full px-3 py-2 border border-gray-300 rounded"
                      :class="{ 'is-invaild': form.errors.has('name') }">
                    <has-error :form="form" field="name"></has-error>
                  </div>
                  <div class="mb-4">
                    <label>Código (DNI/CE)</label>
                    <input v-model="form.email" type="text" name="email" placeholder="Código"
                      class="w-full px-3 py-2 border border-gray-300 rounded"
                      :class="{ 'is-invaild': form.errors.has('email') }">
                    <has-error :form="form" field="email"></has-error>
                  </div>
                  <div class="mb-4">
                    <label>Escoger rol</label>
                    <select v-model="form.role" class="w-full px-3 py-2 border border-gray-300 rounded"
                      :class="{ 'is-invaild': form.errors.has('role') }">
                      <option v-for="role in roles" :key="role.id" :value="role.id">
                        {{ role.name }}
                      </option>
                    </select>
                    <has-error :form="form" field="role"></has-error>
                  </div>
                  <div class="mb-4">
                    <label>Contraseña</label>
                    <input v-model="form.password" type="password" name="password" placeholder="Password"
                      class="w-full px-3 py-2 border border-gray-300 rounded"
                      :class="{ 'is-invaild': form.errors.has('password') }">
                    <has-error :form="form" field="password"></has-error>
                  </div>
                </div>
                <div class="modal-footer justify-between">
                  <button type="button" class="btn btn-lg btn-dange" @click="showModal = false">Cerrar</button>
                  <button variant="primary" v-if="!load" class="btn-lg" disabled>
                    Creando cuenta....
                  </button>
                  <button type="submit" v-if="load" class="btn btn-lg btn-primary  ml-2">Guardar nuevo usuario</button>
                </div>
              </form>
            </div>
          </div>

        </div>

      </modal>
      <!-- End Create User Modal -->

      <!-- Assign Section Modal -->
      <modal :showModal="showSectionFormModal" @close="showSectionFormModal = false">

        <div class="modal fade show" id="assignSection" tabindex="-1" role="dialog"
          aria-labelledby="assignSectionToUser">
          <div class="modal-dialog max-w-lg mx-auto" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title font-bold">Asignar sección Usuario</h5>

                <form @submit.prevent="updateUser()">
                  <div class="modal-body">
                    <div class="mb-4">
                      <select v-model="sectionForm.section_id" class="w-full px-3 py-2 border border-gray-300 rounded"
                        :class="{ 'is-invalid': sectionForm.errors.has('section_id') }">
                        <option v-for="section in sections" :key="section.id" :value="section.id">
                          {{ section.name }}
                        </option>
                      </select>
                      <has-error :form="sectionForm" field="role"></has-error>
                    </div>
                  </div>
                  <div class="modal-footer justify-between mt-4">
                    <button type="button" class="btn btn-lg btn-danger"
                      @click="showSectionFormModal = false">Cerrar</button>
                    <button variant="primary" v-if="!load" class="btn-lg" disabled>
                      Guardando usuario....
                    </button>
                    <button type="submit" v-if="load" class="btn btn-lg btn-primary ml-2">Guardar cambio</button>
                  </div>
                </form>

              </div>

            </div>

            <!-- End Assign Section Modal -->
          </div>
        </div>
      </modal>



    </div>
  </div>

</template>
<script>
import Form from 'vform';
import axios from 'axios';
export default {
  data() {
    return {
      load: true,
      users: [],
      roles: [],
      permissions: [],
      showModal: false,
      showSectionFormModal: false,
      selectedUserId: null,
      selectedSectionId: null,
      showAll: false,
      classMap: {
        "Docente": "fill-blue-500",
        "Director": "fill-green-500",
        "Administrador": "fill-red-500",
        "Estudiante": "fill-yellow-500",
        "Especialista": "fill-orange-500",
      },
      form: new Form({
        'name': '',
        'phone': '',
        'password': '',
        'email': '',
        'permissions': [],
        'role': 3,
      }),
      sections: [],
      sectionForm: new Form({
        'user_id': '',
        'section_id': '',
      })
    };
  },
  methods: {
    getRoles() {
      axios.get('/getAllRoles').then((response) => {
        this.roles = response.data.roles
      });
    },
    fetchAll(){
      axios.get('/getAllUsers?all=1').then((response) => {
        this.users = response.data.users
        this.showAll = true;
      }).catch(() => {
        alert("Cannot load users")
      })
    },
    getUsers() {
      axios.get('/getAllUsers').then((response) => {
        this.users = response.data.users
      }).catch(() => {
        alert("Cannot load users")
      })
    },
    getSections() {
      axios.get('/sections').then((response) => {
        this.sections = response.data.sections;
        this.sectionForm.section = this.sections[0].id;
      });
    },
    getPermissions() {
      axios.get('/getAllPermissions').then((response) => {
        this.permissions = response.data.permissions
      });
    },
    createUser() {
      this.load = false;
      this.form.post("/account/create").then((response) => {
        this.load = true;
        alert("usuario creado")
        this.showModal = false;
        this.getUsers();
        this.form.reset();
      }).catch(() => {
        this.load = true;
        alert("No pudimos crear el usuario, intenta nuevamente")
      });
    },
    showSectionModal(id, section_id) {
      this.showSectionFormModal = true;
      this.sectionForm["user_id"] = id;
      if (section_id) {
        this.sectionForm["section_id"] = section_id;
      }
    },
    updateUser() {
      this.load = false;
      this.sectionForm.post("/users/assign").then((response) => {
        this.load = true;
        alert("Usuario actualizado")
        this.showSectionFormModal = false;
        this.getUsers();
        this.sectionForm.reset();
      }).catch(() => {
        this.load = true;
        alert("No pudimos actualizar el usuario, intenta nuevamente")
      });
    }
  },
  created() {
    this.getUsers();
    this.getSections();
    this.getRoles();
    this.getPermissions();
  },

};
</script>
<style></style>
