<template>
  <div>
    <div class="font-bold mt-5" :id="'question_' + question.id">{{parseInt(question.order) + 1}}. {{ question.statement }}</div>
  <div class="mt-2 ml-5">
      <div class="my-2 question-custom-radio" v-for="qo in options">
        <input :id="'question_' + question.order + 'question_option' + qo.order" :name="'question_option['+ question.id + ']'"
               type="radio" :value="qo.id" @click="answerQuestion(); $emit('answered-question', qo.order)">
        <label :for="'question_' + question.order + 'question_option' + qo.order">{{ String.fromCharCode(97+ parseInt(qo.order)) + ')' }} {{qo.answer}}</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TestQuestions",
  props: {
    question: {
      type: Object
    },
    options: {
      type: Array
    }
  },
  methods: {
    answerQuestion: function (){
      say(['Sigue asi', 'Tu puedes', 'Vas bien'][Math.floor(Math.random() * 3)]);
      new Audio('/audio/correct_choice.wav').play()
    }
  }
}
</script>

<style scoped>

</style>