<template>
  <div>
    <canvas id="barChart"></canvas>
  </div> 
</template>
<script>
import { Chart as ChartJS, BarController, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(BarController, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'BarChart',
  props: {
    labels: {
      type: Array,
      required: true,
    },
    datasets: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      chartData: {
        labels: this.labels,
        datasets: this.datasets,
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: this.title,
          fontSize: 24,
        }
      }
    }
  },
  mounted(){
   const ctx = document.getElementById('barChart');

    new ChartJS(ctx, {
      type: 'bar',
      data: this.chartData,
      options: this.chartOptions,
    });
  }

}
</script>

<style scoped></style>