import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "mt-2 ml-5" }
const _hoisted_3 = { class: "my-2 question-custom-radio" }
const _hoisted_4 = ["id", "name", "value", "onClick"]
const _hoisted_5 = ["for"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: "font-bold mt-5",
      id: 'question_' + $props.question.id
    }, _toDisplayString(parseInt($props.question.order) + 1) + ". " + _toDisplayString($props.question.statement), 9 /* TEXT, PROPS */, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.options, (qo) => {
        return (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("input", {
            id: 'question_' + $props.question.order + 'question_option' + qo.order,
            name: 'question_option['+ $props.question.id + ']',
            type: "radio",
            value: qo.id,
            onClick: $event => {$options.answerQuestion(); _ctx.$emit('answered-question', qo.order)}
          }, null, 8 /* PROPS */, _hoisted_4),
          _createElementVNode("label", {
            for: 'question_' + $props.question.order + 'question_option' + qo.order
          }, _toDisplayString(String.fromCharCode(97+ parseInt(qo.order)) + ')') + " " + _toDisplayString(qo.answer), 9 /* TEXT, PROPS */, _hoisted_5)
        ]))
      }), 256 /* UNKEYED_FRAGMENT */))
    ])
  ]))
}