<template>
  <div>
    <div class="" v-if="!this.disable_say">
      <transition name="bounce">
        <div class="font-bold pb-4 relative" v-if='message.length > 0' style="z-index: 999!important">
          <div style="background: #5e6bf2; padding: 10px; border-radius: 10px; color: white; float: left;"
            v-text="this.message"> </div>
        </div>
      </transition>
    </div>
    <div class="h-32 md:h-56 aspect-[1/1.5] relative mx-auto" @click="sayRandomPhrase()"
      v-if="options.length">
      <img v-for="option in options" :src="'/storage/' + option.image" :alt="'Imagen de un ' + option.name"
        :class="'absolute inset-0 mx-auto object-contain'" :style="'z-index: ' + option.avatar_option_type.order">
    </div>
  </div>
</template>

<script>
export default {
  name: "avatar",
  props: ['options', 'height', 'width', 'disable_say'],
  data: function () {
    return {
      a_height: this.height ? this.height : 250,
      a_width: this.width ? this.width : 160,
      message: ''
    }
  },
  mounted() {
  },
  methods: {
    sayRandomPhrase: function () {
      let randomPhrases = [
        "Hola",
        "Como vas?",
        "Me haces cosquillas",
        "Solo estudiando logras el éxito",
        "La lectura es a la mente, lo que el ejercicio al cuerpo",
        "Estamos habitados por libros y por amigos.",
      ]
      this.say(randomPhrases[Math.floor(Math.random() * randomPhrases.length)]);
    },
    say: function (message, duration = 2000) {
      if (this.disable_say) return;
      this.message = message;
      var that = this;
      setTimeout(function () {
        that.message = '';
      }, duration)
    }
  }
}
</script>

<style scoped></style>
