import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "flex space-x-2" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = ["href", "onClick"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("ul", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
          return (_openBlock(), _createElementBlock("li", {
            class: _normalizeClass([{ 'is-active': tab.isActive }, "block"])
          }, [
            _createElementVNode("a", {
              href: tab.href,
              onClick: $event => ($options.selectTab(tab)),
              class: "nav-link"
            }, _toDisplayString(tab.name), 9 /* TEXT, PROPS */, _hoisted_3)
          ], 2 /* CLASS */))
        }), 256 /* UNKEYED_FRAGMENT */))
      ])
    ]),
    _renderSlot(_ctx.$slots, "default")
  ]))
}