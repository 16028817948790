<template>
  <div class="max-w-screen-xl mx-auto mt-4 px-4 lg:px-0">
    <div class="grid grid-cols-1 md:grid-cols-3 gap-4 text-center md:text-left">
      <div class="flex flex-col items-center md:items-start">
        <h1 class="title">Resumen lecturas</h1>
        <p class="text-gray-600">Este reporte muestra el detalle y el total de lecturas por cada alumno.</p>
      </div>
      <div class="flex justify-center items-center">
        <div class="flex flex-col md:flex-row space-x-4 items-center">
          <label class="shrink-0">Filtrar por alumno</label>
          <input class="form-input" type="text" v-model="filterInput">
        </div>
      </div>
      <div class="flex justify-center md:justify-end items-center">
        <download-excel :data="filteredData" class="btn btn-primary" v-if="filteredData.length > 0">Exportar
          Excel</download-excel>
      </div>
    </div>
    <div class="overflow-x-auto">
      <table class="mt-10 w-full">
        <thead>
          <tr>
            <th class="text-gray-600 px-2 uppercase text-sm"> Alumno </th>
            <th v-if="showSchool" class="text-gray-600 px-2 uppercase text-sm"> Sección </th>
            <th v-if="showSchool" class="text-gray-600 px-2 uppercase text-sm"> Escuela </th>
            <th v-if="showRei" class="text-gray-600 px-2 uppercase text-sm"> REI </th>
            <th class="text-gray-600 px-2 uppercase text-sm"> Lectura </th>
            <th class="text-gray-600 px-2 uppercase text-sm"> Resp Correcta </th>
            <th class="text-gray-600 px-2 uppercase text-sm"> Tiempo utilizado </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in filteredData" class="border-b">
            <td class="font-bold" v-text="row.name"></td>
            <td v-if="showSchool">
              {{ row.grade_name }}
              "{{ row.section_name }}"
            </td>
            <td v-if="showSchool" class="px-2 py-2" v-text="row.school_name"></td>
            <td v-if="showRei" class="px-2 py-2" v-text="row.rei_name"></td>
            <td class="px-2 py-2" v-text="row.reading_name"></td>
            <td class="px-2 py-2" v-text="row.points_s"></td>
            <td class="px-2 py-2" v-text="row.elapse_time_min"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rows: {
      type: Array,
      required: true
    },
    showRei: {
      type: Boolean,
      default: false
    },
    showSchool: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    filteredData() {
      return this.rows.filter(row => {
        return row.name.toLowerCase().includes(this.filterInput.toLowerCase())
      });
    }
  },
  data: function () {
    return {
      filterInput: "",
    }
  },
  methods: {
    filterDataSource: function ({ type, target }) {

    }
  }
}
</script>

<style scoped></style>