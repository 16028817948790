<template>
  <div class="flex items-center mx-3 mb-2">
    <div class="w-full sm:w-2/3 flex items-center">
      <label class="mr-1 px-2">{{ String.fromCharCode(97+order) + ')' }}</label>
      <input v-bind:name="'questions['+ qid +'][question_options]['+ order +'][answer]]'" v-model="option.answer" type="text" class="form-input" required>
    </div>
<div class="w-full sm:w-1/6 my-1">
  <div class="flex items-center px-2">
    <input :id='"is_correct_answer_" + qid + "_" + order'
      v-bind:name="'questions['+ qid +'][question_options]['+ order +'][is_correct_answer]'"
      v-model="option.is_correct_answer" 
      type="checkbox" 
      class="h-5 w-5 text-blue-600">
    <label class="ml-2 text-gray-700" :for='"is_correct_answer_" + qid + "_" + order'>Es la respuesta</label>
  </div>
</div>
    <div class="w-full sm:w-1/6 my-1">
      <button @click.prevent="delCallback(order)" class="btn btn-outline-danger">Eliminar</button>
    </div>
    <input v-bind:name="'questions['+ qid +'][question_options]['+ order +'][order]]'" :value="order" type="hidden">
  </div>
</template>

<script>
export default {
  name: "question-options-form",
  props: [
    'order',
    'option',
    'qid',
    'delCallback',
  ],
}
</script>

<style scoped>

</style>