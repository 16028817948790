<template>
  <div class="overflow-x-auto">
    <div class="w-[780px] mx-auto">
      <canvas id="myChart" class="h-64 w-full"></canvas>
    </div>
 </div>
</template>

<script>
import { Chart as ChartJS, BarController, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
ChartJS.register(BarController, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  name: "level-chart",
  props: ['moduleData', 'yLabels', 'colors', 'title'],
  data() {
    return {
      yIds: [],
      xAxisLabels: [],
      myChart: null,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: this.title,
          fontSize: 24,
        },
        scales: {
          y: {
            stacked: true,
          },
          x: {
            stacked: true,
          },
        },
      },
    };
  },
  computed: {
    processedData() {
      let data = [];
      for (let i = 0; i < this.moduleData.length; i++) {
        this.xAxisLabels.push(this.moduleData[i].name);
      }

      for (let i = 0; i < this.yLabels.length; i++) {
        let dataset = {};
        dataset.data = [];
        for (let j = 0; j < this.moduleData.length; j++) {
          dataset.data.push(this.moduleData[j].data[i]);
        }
        dataset.label = this.yLabels[i];
        dataset.backgroundColor = this.colors[i];
        dataset.borderWidth = 0;
        dataset.yAxisId = 'y-axis-level-' + i;
        this.yIds.push({ id: dataset.yAxisId });
        data.push(dataset);
      }

      return {
        labels: this.xAxisLabels,
        datasets: data,
      };
    },
  },
  methods: {
    initializeChart() {
      const ctx = document.getElementById('myChart');
      if (!ctx) return;

      this.myChart = new ChartJS(ctx, {
        type: 'bar',
        data: this.processedData,
        options: this.chartOptions,
      });
    },
    updateChart() {
      if (this.myChart) {
        this.myChart.data = this.processedData;
        this.myChart.update();
      }
    },
  },
  mounted() {
    this.initializeChart();

    window.addEventListener("resize", () => {
      this.updateChart();
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {
      this.updateChart();
    });
  },
};
</script>

<style scoped></style>
