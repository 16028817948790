<template>
  <div>
    <canvas id="pieChart"></canvas>
  </div> 
</template>
<script>

import { Chart as ChartJS, ArcElement, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PieController } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(PieController, ArcElement, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ChartDataLabels)

export default {
  name: 'PieChart',
  props: {
    labels: {
      type: Array,
      required: true,
    },
    datasets: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      chartData: {
        labels: this.labels,
        datasets: this.datasets,
      },
      chartOptions: {
        tooltips: {
          enabled: false
        },
        plugins: {
          datalabels: {
            formatter: (value, ctx) => {

              let sum = 0;
              let dataArr = ctx.chart.data.datasets[0].data;
              dataArr.map(data => {
                sum += data;
              });
              let percentage = (value * 100 / sum).toFixed(2) + "%";
              return percentage;


            },
            color: '#fff',
          }
        },
        cutout: "50%",
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: this.title,
          fontSize: 24,
        }
      }
    }
  },
  mounted(){
   const ctx = document.getElementById('pieChart');

  new ChartJS(ctx, {
    plugins: [ChartDataLabels],
    type: 'pie',
    data: this.chartData,
    options: this.chartOptions,
  });
  }
}
</script>

<style scoped></style>