<template>
  <div class="flex flex-col items-center justify-center">
    <button class='btn' :class="{'btn-primary': !isRecording, 'btn-outline-primary': isRecording}" type="button" @click="recordAudio()" v-text="recordButtonText"></button>
    <div v-if="timer > 0" class="mt-4">
      Grabando... <span class="stat" v-text="timer"></span> segundos
    </div>
  </div>
</template>

<script>
export default {
  name: "AudioRecording",
  props: ['reading_id', 'csrf'],
  data() {
    return {
      recordButtonText: 'Grabar mientras leo',
      audioStream: false,
      timer: 0,
      mediaRecorder: false,
      recording: false,
      recordedBlobs: [],
      isRecording: false,
      timerInterval: null,
      uploadProgress: 0,
    }
  },
  methods: {
    uploadRecording() {
      this.recordButtonText = 'Subiendo...'

      var fd = new FormData();
      fd.append('audio_file', this.recording, 'student-recording.webm');
      fd.append('_token', this.csrf);
      fd.append('_method', 'PUT');

      var xhr = new XMLHttpRequest();
      xhr.open('POST', '/library/' + this.reading_id, true);
      xhr.onload = function (e) { };
      // Listen to the upload progress.
      var that = this;
      xhr.upload.onprogress = function (e) {
        if (e.lengthComputable) {
          that.uploadProgress = (e.loaded / e.total) * 100;
          that.timer = 0;
          that.recordButtonText = 'Subiendo...' + that.uploadProgress;
        }
        if (that.uploadProgress == 100) {
          // update UI
          that.recordButtonText = 'Audio subido correctamente';
          that.displayAudio(that.recording);
        }
      };
      xhr.upload.onerror = function (e) {
        console.error(e);
      }

      xhr.send(fd);
    },

    handleDataAvailable(event) {
      if (event.data && event.data.size > 0) {
        this.recordedBlobs.push(event.data);
      }
    },
    async recordAudio() {
      if (this.isRecording) {
        this.stopRecording();
        return;
      }
      this.recordedBlobs = [];
      this.audioStream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });

      let options = { mimeType: 'audio/webm', audioBitsPerSecond: 256 * 1000 };
      try {
        this.mediaRecorder = new MediaRecorder(this.audioStream, options);
      } catch (e) {
        console.error('Exception while creating MediaRecorder:', e);
        return;
      }
      this.isRecording = true;

      this.mediaRecorder.ondataavailable = this.handleDataAvailable;
      this.mediaRecorder.start(10000000); // collect a max of 166 minutes

      this.recordButtonText = 'Parar grabación';
      var that = this;

      this.timer = 0;
      this.timerInterval = window.setInterval(function () {
        that.timer++;
      }, 1000);
      this.mediaRecorder.onstop = (event) => {
        that.recordButtonText = 'Grabar otra vez';
        // processing
        const superBuffer = new Blob(that.recordedBlobs, { type: 'audio/webm' });

        clearInterval(that.timerInterval);
        that.recording = superBuffer;
        that.isRecording = false;
        that.uploadRecording();
      }
    },
    stopRecording() {
      this.mediaRecorder.stop();
    },
    displayAudio(recording) {
      const audioURL = URL.createObjectURL(recording);
      const audioElement = document.createElement('audio');
      audioElement.controls = true;
      audioElement.src = audioURL;
      
      const textElement = document.createElement('span');
      textElement.className = 'block text-base md:text-lg';
      textElement.innerText = 'Tu última grabación:';

      const audioContainer = document.getElementById('audio-container');
      if (audioContainer) {
        audioContainer.innerHTML = '';
        audioContainer.appendChild(textElement);
        audioContainer.appendChild(audioElement);
      }
    }
  }
}
</script>

<style scoped>
</style>