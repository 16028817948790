<template>
  <div class="ml-4 mb-4">
      <button class="underline" @click="add">Agregar Pregunta</button>
    <div class="mb-4 mt-4">
      <question-form
        v-for="(q, index) in questions"
        v-bind:key="'q' + index"
        v-bind:order="index"
        v-bind:question="q"
        v-bind:delCallback="del"/>
    </div>
  </div>
</template> 
<script>
export default {
  name: "reading-questions-form",
  props: {
    iquestions: {
      type: Array,
      default: () => [],
    }
  },
  data: function(){
    return {
      questions: this.iquestions,
      nextIndex: this.iquestions.length,
    }
  },
  methods: {
    add(e) {
      e.preventDefault();
      this.questions.push({
        order: this.nextIndex++,
        statement: '',
        question_options: [],
      });
    },
    del(index) {
      this.questions.splice(index, 1);
    }
  }
}
</script>

<style scoped>

</style>
