<template>
  <div>
    <div class="flex space-x-2">
      <ul class="flex">
        <li v-for="tab in tabs" :class="{ 'is-active': tab.isActive }" class="block">
          <a :href="tab.href" @click="selectTab(tab)" class="nav-link">{{ tab.name }}</a>
        </li>
      </ul>
    </div>

    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "tab-layout",
  props: {
    name: { required: true },
    selected: { default: false}
  },
  data: function (){
    return {
      tabs: []
    }
  },
  created() {
    this.tabs = this.$children;
  },
  methods: {
    selectTab(selectedTab) {
      this.tabs.forEach(tab => {
        tab.isActive = (tab.name == selectedTab.name);
      });
    }
  }
}
</script>

<style scoped>

</style>